.boton {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: var(--btn);
	color: #fff;
	font-weight: 500;
	border: none;
	cursor: pointer;
	text-transform: capitalize;
	transition: .5s ease all;
	position: relative;
	overflow: hidden;
}

.boton span {
	position: relative;
	z-index: 2;
	transition: .3s ease all;
}

/* primer estilo */

.boton.one::after {
	content: "";
	width: 100%;
	height: 300px;
	background: var(--btn-hover);
	position: absolute;
	z-index: 1;
	top: -300px;
	left: 0;
	transition: .6s ease-in-out all;
	border-radius: 0px 0px 300px 300px;
}

.boton.one:hover::after {
	top: 0;
}

/* segundo estilo */

.boton.two::after {
	content: "";
	width: 100%;
	height: 100%;
	background: var(--btn-hover);
	position: absolute;
	z-index: 1;
	top: -80px;
	left: 0;
	transition: .6s ease-in-out all;
}

.boton.two:hover::after {
	top: 0;
}

/* tercer estilo */

.boton.three::after {
	content: "";
	width: 1px;
	height: 1px;
	background: none;
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	transition: .9s ease-in-out all;
	border-radius: 100px;
	transform-origin: center;
}

.boton.three:hover::after {
	transform: scale(400);
	background: var(--btn-hover);
}

/*boton del formulario */

.formContent__btn{
	display: inline-flex !important;
	align-items: center !important;
	justify-content: center !important;
	background: var(--btn) !important;
	color: #fff !important;
	font-weight: 500 !important;
	border: none !important;
	cursor: pointer !important;
	text-transform: capitalize !important;
	transition: .5s ease all !important;
	position: relative !important;
	overflow: hidden !important;
	width: auto !important;
	padding: 16px 24px !important;
	display: flex !important;
	justify-content: flex-start !important;
}





.button {
	text-decoration: none;
	line-height: 1;
	border-radius: 1.5rem;
	overflow: hidden;
	position: relative;
	box-shadow: 10px 10px 20px rgba(0,0,0,.05);
	background-color: #fff;
	color: #121212;
	border: none;
	cursor: pointer;
  }
  
  .button-decor {
	position: absolute;
	inset: 0;
	background-color: var(--clr);
	transform: translateX(-100%);
	transition: transform .3s;
	z-index: 0;
  }
  
  .button-content {
	display: flex;
	align-items: center;
	font-weight: 600;
	position: relative;
	overflow: hidden;
  }
  
  .button__icon {
	width: 48px;
	height: 40px;
	background-color: var(--clr);
	display: grid;
	place-items: center;
  }
  
  .button__text {
	display: inline-block;
	transition: color .2s;
	padding: 2px 1.5rem 2px;
	padding-left: .75rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 150px;
  }
  
  .button:hover .button__text {
	color: #fff;
  }
  
  .button:hover .button-decor {
	transform: translate(0);
  }